/* General styles */
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d5def6;
}

.box {
  max-width: 350px; /* Adjust the max-width based on your design */
  box-shadow: 0px 0px 8px 10px rgba(0, 0, 0, 0.1);
  margin: 3rem 0;
  padding: 1rem;
  border-radius: 15px;
  background-color: #ffffff;
}

.BusinessSec {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #325fd5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  padding: 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.BusinessDetailSec {
  padding: 1rem;
  text-align: left; /* Align text to the left */
}
/* Individual item styles */
.detailItem {
  margin-bottom: 1rem; /* Spacing between items */
}

.detailItem:last-child {
  margin-bottom: 0; /* No margin for the last item */
}

.detailTitle {
  font-size: 12px; /* Adjust the title font size as needed */
  color: #333; /* Title color */
  margin-bottom: 0.25rem; /* Space between title and content */
}

.detailContent {
  font-size: 15px; /* Adjust content font size as needed */
  color: #000; /* Content text color */
  font-weight: 600; /* Make content text bold */
}
.shadowBar {
  background-color: transparent; /* Set the shadow bar's background color */
  padding: 1px; /* Adjust padding as needed */
  width: 108.5%; /* Set the width to 100% + some extra to span the entire container */
  margin-left: -16px; /* Negative margin to extend the shadow beyond the container */
  position: relative; /* Add position relative to the parent for absolute positioning of ::after */
}

/* Add a pseudo-element for the glow effect */
.shadowBar::after {
  content: ""; /* Add content to the pseudo-element */
  position: absolute; /* Position absolute to overlap the content */
  bottom: -10px; /* Position it slightly below the container */
  left: 0; /* Position at the left edge of the element */
  width: 100%; /* Span the entire width of the element */
  height: 10px; /* Set the height of the glow effect */
  background: linear-gradient(rgba(0, 0, 0, 0.1), transparent);
}

.footer {
  padding: 1rem;
  text-align: center; /* Center the footer content */
}
.submitBtn {
  width: auto;
  border-radius: 40px;
  width: 12rem;
  height: 3rem;
  background-color: #325fd5;
  color: white;
  font-size: 17px;
  box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.image {
  width: 80px; /* Adjust the size based on your design */
  height: 80px; /* Adjust the size based on your design */
  border: 1px solid white;
  border-radius: 50%;
}
/* Default button size which will allow 4 in a row on larger screens */
.socialButton {
  /* Remove the size prop and control the size with padding */
  padding: 5px; /* This will increase the total size of the button */

  /* Optional: if you want to set exact dimensions */
  width: 55px; /* Set the width of the button */
  height: 55px; /* Set the height of the button */
  border-radius: 50%; /* Keeps it circular */

  /* Flex properties to center the icon */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Style for the icons to ensure they scale properly */
.socialButton svg {
  width: 30px; /* Size of the icon */
  height: 30px; /* Size of the icon */
}

/* Media query for mobile screens */
@media (max-width: 300px) {
  .socialButton {
    flex: 0 1 2%; /* Adjust this value so that two buttons fit in one row */
    margin: 1%; /* Ensure there is a little margin on the sides */
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .box {
    width: 90%; /* Adjust the width for smaller screens */
  }

  .BusinessSec {
    padding: 0.5rem;
  }

  .submitBtn {
    width: 100%; /* Make the button full-width on smaller screens */
  }
}
